(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
exports.init = function($) {
  $menu_elem = $("nav");
  $menu_elem.find(".burger-toggle.label").click(function(e) {
    $check = $(e.currentTarget).find("input[type=checkbox]");
    //console.log($check);
    //$("body").toggleClass("fixed", $check[0].checked);
    if ($check[0].checked) {
      $check[0].checked = false;
    } else {
      $check[0].checked = true;
    }
  });

  $mobile_menu_top_items = $menu_elem
    .find("ul.main-menu-mobile")
    .children(".menu-item-has-children");
  $mobile_menu_top_items.click(function(e) {
    $drawer = $(e.currentTarget).find(".main-menu-mobile-children");
    $(".main-menu-mobile-children").removeClass("active");
    $drawer.addClass("active");
  });

  $mobile_menu_mid_items = $mobile_menu_top_items.find(
    ".main-menu-mobile-children li"
  );
  $mobile_menu_mid_items.click(function(e) {
    $(e.currentTarget).toggleClass("active");
  });

  $mobile_menu_mid_headers = $mobile_menu_top_items.find(
    ".main-menu-mobile-children h3"
  );
  $mobile_menu_mid_headers.click(function(e) {
    $(".main-menu-mobile-children").removeClass("active");
    e.stopPropagation();
    e.preventDefault();
  }); 
  $menu_elem.find('.main-menu-mobile li.menu-item-has-children i').click(function(e) {
     $(e.currentTarget).closest('li').toggleClass('mobilemenuopen');
   });
};

}).call(this,require("e/U+97"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components\\menu.js","/components")