(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
/* modules */
var globals = require('./common/globals.js');
const $ = jQuery;

var menu = require('./components/menu.js');
var footer = require('./components/footer.js');
var flipBox = require('./components/flip-box.js');
var blockUI = require('./components/jquery.blockUI.js');

module.exports = function () {
    
    $(document).ready(function(){
        globals.init(jQuery);
        menu.init(jQuery);
        footer.init(jQuery);
        flipBox.init(jQuery);
        //blockUI.init(jQuery);
    });
    
}();
}).call(this,require("e/U+97"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_a1c964cc.js","/")