(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
exports.init = function($) {
  
  $footer_elem = $('footer');
  var orderNA = 'N/A';
  var finalDiscount = 0.0;
  var finalOrderAmount = 0.0;
  var discountAmount  = 0.0;
  var invalidCouponCode = 'INVALID';

  var holidays = [ "12/25/2018", "12/26/2018", "1/01/2019"];

  function noSunday(date){ 
    show = true;
    if(date.getDay() == 0){show = false;}//No Weekends
    for (var i = 0; i < holidays.length; i++) {
        if (new Date(holidays[i]).toString() == date.toString()) {show = false;}//No Holidays
    }
    var display = [show,'',(show)?'':'No Weekends or Holidays'];
    return display;
  }

  function addDays(startDate,numberOfDays){
    var returnDate = new Date(
                startDate.getFullYear(),
                startDate.getMonth(),
                startDate.getDate()+numberOfDays,
                startDate.getHours(),
                startDate.getMinutes(),
                startDate.getSeconds());
    return returnDate;
  }

  if( $('#datepicker').length ) {
    var today = new Date();
    //var numC = parseInt(phpData.countN);
    var numC = 0;
    var date = addDays(today, numC);
    var day = date.getDay();
    if(day > 0){
      add = numC;
    }else{
      add = numC+1;
    }
    
    $('#datepicker').datepicker({
      minDate: numC,
      beforeShowDay: noSunday
    });
    $('#datepicker').datepicker( 'setDate', '+'+add );
  }

  $('#recalculate').submit( function(event) {
    event.preventDefault();
  });

  //Tabs for FAQs
  $(".tabs_listing .tablinks").click(function(){
    var id, tabcontent, tablinks;
    dat = $(this).data(id);
    tabcontent = $('.tabcontent');
    count = tabcontent.length;
    tabcontent.each(function(event){
        $(this).hide();
    });
    tablinks = $('.tablinks');
    tablinks.each(function(event){
        if($(this).hasClass('active')){
          $(this).removeClass('active');
        }
    });
    $('#'+dat.id).show();
    $(this).addClass('active');

  });
  $("#defaultOpen").click();

  $footer_elem.find('.footer-menu .footer-menu-title').click(function(e) {
    $(e.currentTarget).closest('li').toggleClass('open');
  });

  //for coupon form on product page
  $("#add_coupon_submit").click( function(event) {
    event.preventDefault();
    var couponCode = $('#coupon_code').val();

    var data = {
      action: 'checkif_valid_coupon',
      coupon_code: couponCode
    };

    if ( $.trim(couponCode).length == 0 ) {
        $('#single_product_applycoupon_error_message').text('Please enter a coupon code to proceed');
        $('#single_product_applycoupon_error_message').show();

        return;
    }
       
    $(document).ajaxStart($.blockUI({
      message: null,
      overlayCSS:
      {
        background: '#fff url(\'\') no-repeat center center',
        opacity: 0.6
      }
    })).ajaxStop($.unblockUI);

    $.post('/wp-admin/admin-ajax.php', data, function (response) {
      var responseJSON = JSON.parse(response);
      discountAmount = parseFloat(responseJSON['coupon-amount']);

      if(discountAmount > 0) {

        $('#apply-coupon-form').hide();
        $('#remove-coupon-form').show();
        $('.invalid-coupon-text').hide();

        var qty = $('#order-quantity-field').val();
        var fuelType = $('#order-fueltype').val();

        finalDiscount = qty * discountAmount * 0.01;
        finalDiscount = parseFloat(finalDiscount).toFixed(2);
        
        var orderAmount = $('#order-amount-field').val();
        finalOrderAmount = orderAmount - finalDiscount;

        orderAmount = parseFloat(orderAmount).toFixed(2);
        finalOrderAmount = parseFloat(finalOrderAmount).toFixed(2);
        
        $('#order-amount-field').val(finalOrderAmount);
        $('#yourqoute-text').text('Your Quote : €' + finalOrderAmount);
        $('#total-ordervalue').val(orderAmount);
       
        $('#coupon-code').val(responseJSON['coupon-code']);
        $('#discount-amount').val(finalDiscount);

        adjustSummaryCard(fuelType, qty, finalDiscount, orderAmount, finalOrderAmount);

      } else {
        $('.invalid-coupon-text').show();
        $('#coupon-code').val(invalidCouponCode);

        finalDiscount = 0.0;
      }
    });  
  });

  //for coupon form on product page
  $("#mobile_add_coupon_submit").click( function(event) {
    event.preventDefault();
    var couponCode = $('#mobile_coupon_code').val();

    var data = {
      action: 'checkif_valid_coupon',
      coupon_code: couponCode
    };

    if ( $.trim(couponCode).length == 0 ) {
        $('#single_product_applycoupon_error_message').text('Please enter a coupon code to proceed');
        $('#single_product_applycoupon_error_message').show();

        return;
    }
       
    $(document).ajaxStart($.blockUI({
      message: null,
      overlayCSS:
      {
        background: '#fff url(\'\') no-repeat center center',
        opacity: 0.6
      }
    })).ajaxStop($.unblockUI);

    $.post('/wp-admin/admin-ajax.php', data, function (response) {
      var responseJSON = JSON.parse(response);
      discountAmount = parseFloat(responseJSON['coupon-amount']);

      if(discountAmount > 0) {

        $('#mobile-apply-coupon-form').hide();
        $('#mobile-remove-coupon-form').show();
        $('.invalid-coupon-text').hide();

        var qty = $('#order-quantity-field').val();
        var fuelType = $('#order-fueltype').val();

        finalDiscount = qty * discountAmount * 0.01;
        finalDiscount = parseFloat(finalDiscount).toFixed(2);
        
        var orderAmount = $('#order-amount-field').val();
        finalOrderAmount = orderAmount - finalDiscount;

        orderAmount = parseFloat(orderAmount).toFixed(2);
        finalOrderAmount = parseFloat(finalOrderAmount).toFixed(2);
        
        $('#order-amount-field').val(finalOrderAmount);
        $('#yourqoute-text').text('Your Quote : €' + finalOrderAmount);
        $('#total-ordervalue').val(orderAmount);
       
        $('#coupon-code').val(responseJSON['coupon-code']);
        $('#discount-amount').val(finalDiscount);

        adjustSummaryCard(fuelType, qty, finalDiscount, orderAmount, finalOrderAmount);

      } else {
        $('.invalid-coupon-text').show();
        $('#mobile_coupon_code').val(invalidCouponCode);

        finalDiscount = 0.0;
      }
    });  
  });

  //for coupon form on product page
  $("#remove_coupon_submit").click( function(event) {
    event.preventDefault(); 

    //set discount to 0 and coupon code to blank
    finalDiscount = 0.0;
    $('#coupon-code').val('');
    $('#discount-amount').val(0);

    var county = $('#order-county-field').val();
    var fuelType = $('#order-fueltype').val();
    var qty = $('#order-quantity-field').val();

    var orderAmount = calculateOrderAmount(county, qty, fuelType );
    finalOrderAmount = orderAmount - finalDiscount;

    orderAmount = parseFloat(orderAmount).toFixed(2);
    finalOrderAmount = parseFloat(finalOrderAmount).toFixed(2);
    
    $('#order-amount-field').val(finalOrderAmount);
    $('#yourqoute-text').text('Your Quote : €' + finalOrderAmount);
    
    $('#total-ordervalue').val(orderAmount);

    $('#apply-coupon-form').show();
    $('#remove-coupon-form').hide();
    $('.invalid-coupon-text').hide();

    adjustSummaryCard(fuelType, qty, finalDiscount, orderAmount, finalOrderAmount);

  });


  //for coupon form on product page
  $("#mobile_remove_coupon_submit").click( function(event) {
    event.preventDefault(); 

    //set discount to 0 and coupon code to blank
    finalDiscount = 0.0;
    $('#mobile_coupon_code').val('');
    $('#discount-amount').val(0);

    var county = $('#order-county-field').val();
    var fuelType = $('#order-fueltype').val();
    var qty = $('#order-quantity-field').val();

    var orderAmount = calculateOrderAmount(county, qty, fuelType );
    finalOrderAmount = orderAmount - finalDiscount;

    orderAmount = parseFloat(orderAmount).toFixed(2);
    finalOrderAmount = parseFloat(finalOrderAmount).toFixed(2);
    
    $('#order-amount-field').val(finalOrderAmount);
    $('#yourqoute-text').text('Your Quote : €' + finalOrderAmount);
    
    $('#total-ordervalue').val(orderAmount);

    $('#mobile-apply-coupon-form').show();
    $('#mobile-remove-coupon-form').hide();
    $('.invalid-coupon-text').hide();

    adjustSummaryCard(fuelType, qty, finalDiscount, orderAmount, finalOrderAmount);

  });

  //if County is changed in the quotation form, calculate order amount accordingly
  $('#orderform-county').change(function(){
    var county = $(this).val();
    var countyL = county.toLowerCase();
    var isredirect = checkandredirect(countyL);

    
    if (countyL == 'cavan') {
      return ulp_open('kgYKgoFxANjVhZHa');
    } else if (countyL === 'kildare') {
      return ulp_open('egykuwQOOUefnx2P');
    } else if (countyL === 'kilkenny') {
      return ulp_open('yJfYiVFCqWNew3I8');
    } else if (countyL === 'offaly') {
      return ulp_open('PdpOWmR2TA83dVcp');
    } 
  });
  
  //if County is changed in the quotation form, calculate order amount accordingly
  $('#order-county-field').change(function(){
      var county = $('#order-county-field').val();
      var countyL = county.toLowerCase();
      var fuelType = $('#order-fueltype').val();
      var qty = $('#order-quantity-field').val();

      if (countyL == 'cavan') {
        return ulp_open('kgYKgoFxANjVhZHa');
      } else if (countyL === 'kildare') {
        return ulp_open('egykuwQOOUefnx2P');
      } else if (countyL === 'kilkenny') {
        return ulp_open('yJfYiVFCqWNew3I8');
      } else if (countyL === 'offaly') {
        return ulp_open('PdpOWmR2TA83dVcp');
      }

      var orderAmount = calculateOrderAmount(county, qty, fuelType );
      finalOrderAmount = orderAmount - finalDiscount;

      orderAmount = parseFloat(orderAmount).toFixed(2);
      finalOrderAmount = parseFloat(finalOrderAmount).toFixed(2);
      
      $('#order-amount-field').val(finalOrderAmount);
      $('#yourqoute-text').text('Your Quote : €' + finalOrderAmount);
      
      $('#total-ordervalue').val(orderAmount);

      $( ".counties-selectbox" ).each(function( index ) {
        var town_id = county.toLowerCase() + "-townslands"; 
        $(this).css({"display":"none"});
        if(this.id.toLowerCase() == town_id) {
          $(this).css({"display":"block"});
        } else {
          $(this).css({"display":"none"});
        }
      });

      adjustSummaryCard(fuelType, qty, finalDiscount, orderAmount, finalOrderAmount);
  });

  //if Fueltype is changed in the quotation form, calculate order amount accordingly
  $('#order-fueltype').change(function () { 
    
    var county = $('#order-county-field').val();
    var fuelType = $('#order-fueltype').val();
    var qty = $('#order-quantity-field').val();
    
    orderAmount = calculateOrderAmount(county, qty, fuelType );
    finalOrderAmount = orderAmount - finalDiscount;

    orderAmount = parseFloat(orderAmount).toFixed(2);
    finalOrderAmount = parseFloat(finalOrderAmount).toFixed(2);
    
    $('#order-amount-field').val(finalOrderAmount);
    $('#yourqoute-text').text('Your Quote : €' + finalOrderAmount);
    
    $('#total-ordervalue').val(orderAmount);

    adjustSummaryCard(fuelType, qty, finalDiscount, orderAmount, finalOrderAmount);

  });
                        
  // If quantity is changed in the quotation form, calculate order amount accordingly
  $('#order-quantity-field').on('input',function(){
      var county = $('#order-county-field').val();
      var fuelType = $('#order-fueltype').val();
      var qty = $('#order-quantity-field').val();
      
      if(qty < 200) {
        $('#order-amount-field').val(orderNA);
        $('input[name="add_to_cart"]').prop('disabled', true);
        $('#minimum_qunatity_message').show();
        $('#yourqoute-text').text('Your Quote : €' + orderNA);
      } else {
        var orderAmount = calculateOrderAmount(county, qty, fuelType );
        finalOrderAmount = orderAmount - finalDiscount;

        orderAmount = parseFloat(orderAmount).toFixed(2);
        finalOrderAmount = parseFloat(finalOrderAmount).toFixed(2);
        $('input[name="add_to_cart"]').prop('disabled', false);
        $('#minimum_qunatity_message').hide();
        $('#order-amount-field').val(finalOrderAmount);
        $('#yourqoute-text').text('Your Quote : €' + finalOrderAmount);

        $('#total-ordervalue').val(orderAmount);

        adjustSummaryCard(fuelType, qty, finalDiscount, orderAmount, finalOrderAmount);
      }
  });

  // If amount is changed in the quotation form, adjust quantity and calculate order amount accordingly
  $('#order-amount-field').focusout(function(){
    var county = $('#order-county-field').val();
    var fuelType = $('#order-fueltype').val();
    var amount = $('#order-amount-field').val();
    var qty = $('#order-quantity-field').val();

    amount = parseFloat(amount);
    qty = parseFloat(qty);

    if(amount == '') {
      
    } else {
      finalDiscount = parseFloat(finalDiscount);
      amount = amount + finalDiscount;
      var orderQty = campusCalculateQuantity(county, amount, fuelType);
      
      if(orderQty < 200) {
        $('#order-quantity-field').val(orderQty);
        $('#order-amount-field').text(orderNA);
        $('#yourqoute-text').text('Your Quote : €' + orderNA);
        
      } else {
        
        $('#order-quantity-field').val(orderQty);
        finalDiscount = orderQty * discountAmount * 0.01;
        var preciseAmount = calculateOrderAmount(county, orderQty, fuelType);
        finalOrderAmount = preciseAmount - finalDiscount;

        preciseAmount = parseFloat(preciseAmount).toFixed(2);
        finalOrderAmount = parseFloat(finalOrderAmount).toFixed(2);
        $('#order-amount-field').val(finalOrderAmount);
        $('#yourqoute-text').text('Your Quote : €' + finalOrderAmount);
        
        $('#total-ordervalue').val(preciseAmount);
        adjustSummaryCard(fuelType, orderQty, finalDiscount, preciseAmount, finalOrderAmount );
      }
    }
  });

  /* Accepts 
    county name
    fuel type
    quantity 
    returns the amount payable including VAT
  */
  function calculateOrderAmount (countyName, quantity, fuelType) {
    
    var baseRate = 0.0;
    var rebateRate = 0.0;
    
    $.each(base_rate_json, function(index, element) {
        if(element.fuel_value == fuelType) {
          baseRate = element.base_price;
          return false;
        }
    });
    
    $.each(rebate_rate_json, function(index, element) {
      if(element.county_name == countyName && element.fuel_type == fuelType) {
        
        if(quantity >= 200 && quantity <= 288) {
          rebateRate = element.rebate_200_288;
        
        } else if(quantity >= 289 && quantity <= 388) {
          rebateRate = element.rebate_289_388;
      
        } else if(quantity >= 389 && quantity <= 488) {
          rebateRate = element.rebate_389_488;
        
        } else if(quantity >= 489 && quantity <= 588) {
          rebateRate = element.rebate_489_588;
        
        } else if(quantity >= 589 && quantity <= 688) {
          rebateRate = element.rebate_589_688;
        
        } else if(quantity >= 689 && quantity <= 788) {
          rebateRate = element.rebate_689_788;
        
        } else if(quantity >= 789 && quantity <= 888) {
          rebateRate = element.rebate_789_888;
        
        } else if(quantity >= 889 && quantity <= 988) {
          rebateRate = element.rebate_889_988;
        
        } else if(quantity >= 989 && quantity <= 2000) {
          rebateRate = element.rebate_989_2000;
        
        } else {
          rebateRate = element.rebate_989_2000;
        } 
        return false;
      }
    });

    /*if (quantity >= 200 && quantity <= 288) {
      amountExVat = (parseFloat(baseRate) + parseFloat(rebateRate)) * quantity;
    } else {
      amountExVat = (baseRate - rebateRate) * quantity; 
    }*/
    amountExVat = (baseRate - rebateRate) * quantity;
  var orderAmount = amountExVat + (amountExVat * 0.135);
    orderAmount = parseFloat(orderAmount).toFixed(2);
  
    return orderAmount;

  }

  /* adjust order summary rate card when any variable in the order is changed */
  function adjustSummaryCard( summary_fuel, summary_qty, summary_discount, summary_subtotal, summary_total ) {

    var ppl = parseFloat(summary_total/summary_qty).toFixed(2);

    if(summary_discount == 0) {
      $('#order-couponamount').text('€0.00');
    } else {
      $('#order-couponamount').text('€' + summary_discount);
    }

    if(summary_fuel == 'mgo') {
      $('#fuel-name').text('Gas Oil');
    } else {
      $('#fuel-name').text(summary_fuel);
    }

    $('#order-quantity').text(summary_qty);
    $('#order-cpl').text(ppl);
    $('#order-subtotal').text('€' + summary_subtotal);
    $('#order-total').text('€' + summary_total);
  }

  /* this function accepts county name, fuel type and amount and returns the quantity */
  function campusCalculateQuantity (countyName, amount, fuelType) {
    
    var amountWithinLimits = false;
    var amountLowestCap;
    var amountHighestCap;
    var rebateLowestCap;
    var rebateHighestCap;
    
    var orderQuantity;
    var fuelType;
    var VAT = 0.135;
    
    var quantLower;
    var quantUpper;
    
    var columnNames = [ 'rebate_200_288',
              'rebate_289_388',
              'rebate_389_488',
              'rebate_489_588',
              'rebate_589_688',
              'rebate_689_788',
              'rebate_789_888',
              'rebate_889_988',
              'rebate_989_2000' ];
    
    var columnName; 
    var baserate = 0.0;

    $.each(base_rate_json, function(index, element) {
      if(element.fuel_value == fuelType) {
        baserate = element.base_price;
        return false;
      }
    });

    var rebateTemp = 0;
    
    var possibleAmounts = [];
    var possibleRebates = [];
    var posIndex = 0;
    var possibleAmount = 0;
    
    $.each(rebate_rate_json, function(index, element) {

      if(element.county_name == countyName && element.fuel_type == fuelType) {
      
        posIndex = 0;
        
        for( j = 0; j < columnNames.length; j++ ) {
          
          columnName = columnNames[j];
          
          rebateTemp = element[columnName];
          
          if( j == 0 ) {
            
            rebateLowestCap = rebateTemp;
            
            quantLower = 200;
            quantUpper = 288;
            
          } else if ( j == 1 ) {
            
            quantLower = 289;
            quantUpper = 388;
            
          } else if ( j == 2 ) {
            
            quantLower = 389;
            quantUpper = 488;
            
          } else if ( j == 3 ) {
          
            quantLower = 489;
            quantUpper = 588;
            
          } else if ( j == 4 ) {
            
            quantLower = 589;
            quantUpper = 688;
            
          } else if ( j == 5 ) {
            
            quantLower = 689;
            quantUpper = 788;
            
          } else if ( j == 6 ) {
            
            quantLower = 789;
            quantUpper = 888;
            
          } else if ( j == 7 ) {
            
            quantLower = 889;
            quantUpper = 988;
            
          } else if ( j == 8 ) {
            
            rebateHighestCap = rebateTemp;
            
            quantLower = 989;
            quantUpper = 2000;
            
          }
              
          amountLowerLimit = calculateOrderAmount(countyName, quantLower, fuelType);
          amountUpperLimit = calculateOrderAmount(countyName, quantUpper, fuelType);
          
          if( j == 0 ) {
            amountLowestCap = amountLowerLimit;
          }
          
          if( j == 8 ) {
            amountHighestCap = amountUpperLimit;
          }
            
          if( (amountLowerLimit-3) <= amount &&  (amountUpperLimit+3) >= amount ) {
          
            amountWithinLimits  = true;

            orderQuantity = amount / ( 1.135 *  (baserate - rebateTemp));
            orderQuantity = Math.round(orderQuantity);
            
            possibleAmount = calculateOrderAmount(countyName, orderQuantity, fuelType);
            
            possibleAmounts[posIndex] = possibleAmount;
            possibleRebates[posIndex] = rebateTemp;
            
            posIndex = posIndex + 1;
            
          }
        }
        return false;
      }
    });
      
    var finalRebate;
    var orderQuantityFinal;

    if(amountWithinLimits == false ) {
      
      if(amount < amountLowestCap) {
        
        orderQuantityFinal = amount / ( 1.135 *  (baserate - rebateLowestCap));
        orderQuantityFinal = Math.round(orderQuantityFinal);
        
      } else if (amount > amountHighestCap) {
        
        orderQuantityFinal = amount / ( 1.135 *  (baserate - rebateHighestCap));
        orderQuantityFinal = Math.round(orderQuantityFinal);
        
      } else {

      }
      
      
    } else {
      
      finalRebate = possibleRebates[ closestElement (amount, possibleAmounts)]
      
      orderQuantityFinal = amount / ( 1.135 *  (baserate - finalRebate));
      orderQuantityFinal = Math.round(orderQuantityFinal);  
      
    }

    return orderQuantityFinal;
        
  }
  
  function closestElement (num, arr) {
    var curr = arr[0];
    var closestIndex = 0;
      var diff = Math.abs (num - curr);
      for (var val = 0; val < arr.length; val++) {
          var newdiff = Math.abs (num - arr[val]);
          if (newdiff <= diff) {
              diff = newdiff;
              curr = arr[val];
              closestIndex = val;
          }
      }
      return closestIndex;
  }
  $('.footer-menu h4').click(function (e) {
    e.target.scrollIntoView();
  })

  if( $('#billing_deliverydate').length ) {
    var viewportwidth = $( window ).width();
    if(viewportwidth > 768) {
      window.onscroll = function() {stickyFunction()};
    }
    var summary = document.getElementById("order-summary-box");
    var offsets = $('#order-summary-box').offset();
    function stickyFunction() {
      if (window.pageYOffset >= offsets.top) {
        summary.classList.add("sticky");
        summary.style.left = offsets.left + 'px';
      } else {
        summary.style.left = 0 + 'px';
        summary.classList.remove("sticky");
      }
    }
  }

  jQuery('#orderform-quantity, #orderform-fueltype, #order-county-field').change(function(){
    var county = $('#order-county-field').val();
    var countyL;
    if(county){
      countyL = county.toLowerCase();
    }
    //var countyL = county.toLowerCase();
    // console.log(county);
    var fuelType = $('#orderform-fueltype').val();
    // console.log(fuelType);
    var qty = $('#orderform-quantity').val();
    //console.log(qty);
    var amount = calculateOrderAmount(countyL, qty, fuelType );
   // console.log(amount);
   if(countyL && qty && fuelType ){
    jQuery('#order-info-msg span').text(amount);
    }
    //jQuery('#order-info-msg span').text(amount);
  });
}
}).call(this,require("e/U+97"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components\\footer.js","/components")