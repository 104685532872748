(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
exports.init = function ($) {
	var CP = {
		debug:false,
		init: function(){
			if (this.debug) {
				console.log('CP.init()');
			}

			try{
				$('body').addClass('loaded');
				//$('select').formSelect();
			}
			catch(err){
				if (this.debug) {
					console.log(err);
				}
				$('body').addClass('loaded');
			}

		}
	};

	//are we good?
	if ($('body').length) {
		CP.init();
	}
};

}).call(this,require("e/U+97"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/common\\globals.js","/common")