(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
    exports.init = function($) {
    
    $(function(){
        $('.flip-box').hover(function() {
            $(this).addClass('hover');
        }, function() {
            $(this).removeClass('hover');
        })
    })
}
}).call(this,require("e/U+97"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components\\flip-box.js","/components")